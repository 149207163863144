var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]"},[_c('HeaderSlot',{attrs:{"title":_vm.campaign.name,"isBackButtonHidden":false === _vm.isGuest},on:{"clickBackButton":function($event){return _vm.$router.push({ name: 'project', params: { id: _vm.campaign.workspace.id } })}}},[(
			_vm.$hasRight('files.downloadDeliverables')
		)?_c('ButtonSlot',{staticStyle:{"float":"right"},attrs:{"_icon":"mdi-select-group","_theme":"light-gray"},on:{"click":function($event){_vm.selectCoresModal = true}}},[_vm._v(" Export PDF multiple ")]):_vm._e(),(
			_vm.$hasRight('files.uploadDeliverables') && _vm.campaign.type === 'coring'
		)?_c('ButtonSlot',{staticStyle:{"float":"right"},attrs:{"_disabled":_vm.pdfIsGenerating || _vm.campaign.isNotRefreshableProvider === true,"_tooltip":_vm.campaign.isNotRefreshableProvider === true ? 'Ce bouton à été désactivé car une action manuelle à été réalisée sur cette campagne. Contactez un administrateur' : '',"_icon":"mdi-refresh"},on:{"click":_vm.regeneratePdf}},[_vm._v(" Rafraîchir les données ")]):_vm._e(),(_vm.$hasRight('files.accessDeliverables'))?_c('ButtonSlot',{attrs:{"_to":{
			name: 'campaign-deliverables',
			params: { id: _vm.$route.params.id }
		},"_res":1550,"_icon":"mdi-file-check-outline"}},[_vm._v(" Accéder aux livrables ")]):_vm._e(),_c('ButtonSlot',{attrs:{"_to":{
			name: _vm.dashboardComponentName,
			params: { id: _vm.$route.params.id }
		},"_icon":"mdi-file-document-outline"}},[_vm._v(" Tableau de bord ")])],1),_c('CoringMap',{staticClass:"tw-w-full tw-grow tw-flex",attrs:{"id":"coringMap"}},[_c('div',{staticClass:"menu"},[(_vm.cores && _vm.campaign.coringType !== 'CS')?_c('CoringMapFilter',{ref:"filters",attrs:{"isMacroProject":false}}):_vm._e(),(_vm.cores && _vm.campaign.coringType !== 'CS')?_c('CoringMapMenu'):_vm._e()],1),(_vm.cores && _vm.campaign.coringType !== 'CS')?_c('MapFiltre'):_vm._e()],1),(_vm.selectCoresModal)?_c('SelectCoresPopin',{attrs:{"cores":_vm.cores},on:{"closeModal":function($event){_vm.selectCoresModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div 
	class="tw-overflow-hidden tw-relative"
	coring-map
	>
		<Map
		class="tw-w-full tw-h-full" 
		ref="map"
		@load="map=>SET_MAP(map)"
		/>

		<Diaporamap
		class="tw-h-full tw-w-[50%] tw-top-0 tw-right-full"
		@diapoOpeningStateChanged="onDiapoOpeningStateChanged($event)"
		/>

		<slot/>
	</div>
</template>

<script>
import Map from "../map/Map.vue";
import mapboxgl from "mapbox-gl";
import CoringMapMarker from "./Coring.Map.Marker.vue";
import Diaporamap from "./Coring.Diaporamap.vue";
import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
	name: "CoringMap",
	components: {
		Map,
		Diaporamap
	},
	props: {

	},
	data(){
		return {
			markers: [],
		};
	},
	computed: {
		...mapGetters("coring", [
			"cores", "coreOnFocus", "map", "isAddingCore", "campaign", "filter"
		]),
	},
	watch: {
		filter(){
			this.displayCores();
		},
		cores(n, o){
			if((o === false || o.length === 0) && this.cores[0]){
				let {maxLng, minLng} = this.cores.reduce(
					(a, core) => {
						if(core.lng > a.maxLng) a.maxLng = core.lng;
						if(core.lng < a.minLng) a.minLng = core.lng;
						return a;
					}, 
					{maxLng: 0, minLng: Infinity}
				);

				let {maxLat, minLat} = this.cores.reduce(
					(a, core) => {
						if(core.lat > a.maxLat) a.maxLat = core.lat;
						if(core.lat < a.minLat) a.minLat = core.lat;
						return a;
					}, 
					{maxLat: 0, minLat: Infinity}
				);

				let lng = (maxLng + minLng) / 2;
				let lat = (maxLat + minLat) / 2;

				this.$refs.map.center = {lng, lat};
				this.$refs.map.zoom = 15;

				this.$refs.map.bounds = {
					maxLat, minLat, maxLng, minLng
				};
			}
			if(this.cores !== false){
				this.removeCores();
				this.displayCores();
			}
		},
		isAddingCore(){
			if(this.isAddingCore === true){
				this.addingCore();
			}
			else {
				this.unAddingCore();
			}
		}
	},
	methods: {
		...mapActions("coring", ["addCore", "setCoreOnFocus"]),
		...mapMutations("coring", ["SET_MAP", "STOP_ADDING_CORE"]),

		displayCores(){
			let tempCores = [...this.cores];
			if(this.campaign.status === "results_obtained" && this.campaign.hasAsbestos === true && (this.filter === "asbestos" || this.filter === "asbestosDuplicated")){
				tempCores.sort((a, b) => {
				// Fonction pour vérifier si le type est 'layer'
					const isLayer = (item) => item.type === "layer";
    
					// Récupère les couches (layers) avec containsAsbestos à true
					const containsAsbestosLayersA = a.layersAndInterfaces.filter(layer => isLayer(layer) && layer.asbestosResult.containsAsbestos);
					const containsAsbestosLayersB = b.layersAndInterfaces.filter(layer => isLayer(layer) && layer.asbestosResult.containsAsbestos);

					if(containsAsbestosLayersA.length > 0 && containsAsbestosLayersB.length === 0){
						return 1; // core a avant core b
					}
					else if(containsAsbestosLayersA.length === 0 && containsAsbestosLayersB.length > 0){
						return -1; // core b avant core a
					}
					else {
						return 0; // maintient l'ordre actuel
					}
				});
			}

			if(this.campaign.status === "results_obtained" && this.campaign.hasPAH === true && (this.filter === "PAH" || this.filter === "pahDuplicated")){
				tempCores.sort((a, b) => {
				// Fonction pour vérifier si le type est 'layer'
					const isLayer = (item) => item.type === "layer";
    
					// Récupère les couches (layers) avec containsAsbestos à true
					const pahResultLayerA = a.layersAndInterfaces.filter(layer => isLayer(layer) && layer.PAHResult && layer.PAHResult.sum !== null);
					const pahResultLayerB = b.layersAndInterfaces.filter(layer => isLayer(layer) && layer.PAHResult && layer.PAHResult.sum !== null);

					// Obtenir les valeurs de sum de pahResult
					const sumA = pahResultLayerA.length > 0 ? pahResultLayerA[0].PAHResult.sum : 0;
					const sumB = pahResultLayerB.length > 0 ? pahResultLayerB[0].PAHResult.sum : 0;

					// Comparaison des valeurs de sum
					return sumA - sumB;
				});
			}
			
			for(const core of tempCores){
				this.createMaker(core);
			}
		},

		createMaker(core){
			if(core.lng === undefined || core.lat === undefined) return;
			let div = document.createElement("div");
			div.innerHTML = `<div id="map-${this.$refs.map.id}-marker-${core.id}"></div>`;
			let el = new CoringMapMarker({
				propsData: {
					core: core,
				},
				parent: this
			});
			el.$on("popupOpened", this.onPopupOpened);

			this.markers.push({
				vue: el,
				map: new mapboxgl.Marker(div).setLngLat({lng: core.lng, lat: core.lat}).addTo(this.$refs.map.map),
				destroy(){
					this.vue.$destroy();
					this.map.remove();
				},
			});

			el.$mount("#" + div.children[0].id);
		},

		removeCores(){
			for(const marker of this.markers){
				marker.destroy();
			}
		},

		addingCore(){
			this.map.on("click", this.addingCoreEventClick);
			this.map.getCanvas().style.cursor = "crosshair";
		},

		unAddingCore(){
			this.map.off("click", this.addingCoreEventClick);
			this.map.getCanvas().style.cursor = "";
		},

		addingCoreEventClick(e){
			this.addCore({lng: e.lngLat.lng, lat: e.lngLat.lat});
			this.STOP_ADDING_CORE();
		},

		onDiapoOpeningStateChanged(isOpened){
			if(this.coreOnFocus && isOpened){
				let padding = this.map.getCanvas().width / 2;
				this.map.flyTo({
					center: [this.coreOnFocus.lng, this.coreOnFocus.lat],
					padding: {left: padding}
				});
			}
		},

		onPopupOpened(core){
			this.setCoreOnFocus(core);
		},

	},
	created(){
	},
	destroyed(){
	}
};
</script>

<style lang="scss">
div[coring-map]{
    
}
</style>

<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		@clickBackButton="$router.push({ name: 'project', params: { id: campaign.workspace.id } })"
		:title="campaign.name"
		:isBackButtonHidden="false === isGuest"
		>
			<ButtonSlot
			v-if="
				$hasRight('files.downloadDeliverables')
			"
			style="float: right"
			@click="selectCoresModal = true"
			_icon="mdi-select-group"
			_theme="light-gray"
			>
				Export PDF multiple
			</ButtonSlot>
			<ButtonSlot
			v-if="
				$hasRight('files.uploadDeliverables') && campaign.type === 'coring'
			"
			style="float: right"
			@click="regeneratePdf"
			:_disabled="pdfIsGenerating || campaign.isNotRefreshableProvider === true"
			:_tooltip="campaign.isNotRefreshableProvider === true ? 'Ce bouton à été désactivé car une action manuelle à été réalisée sur cette campagne. Contactez un administrateur' : ''"
			_icon="mdi-refresh"
			>
				Rafraîchir les données
			</ButtonSlot>

			<ButtonSlot
			:_to="{
				name: 'campaign-deliverables',
				params: { id: $route.params.id }
			}"
			:_res="1550"
			_icon="mdi-file-check-outline"
			v-if="$hasRight('files.accessDeliverables')"
			>
				Accéder aux livrables
			</ButtonSlot>
			
			<ButtonSlot
			:_to="{
				name: dashboardComponentName,
				params: { id: $route.params.id }
			}"
			_icon="mdi-file-document-outline"
			>
				Tableau de bord
			</ButtonSlot>
		</HeaderSlot>

		<CoringMap
		class="tw-w-full tw-grow tw-flex"
		id="coringMap"
		>
			<div class="menu">
				<CoringMapFilter
				v-if="cores && campaign.coringType !== 'CS'"
				:isMacroProject="false"
				ref="filters"
				/>
				<CoringMapMenu
				v-if="cores && campaign.coringType !== 'CS'"
				/>
			</div>

			<MapFiltre
			v-if="cores && campaign.coringType !== 'CS'"
			/>
		</CoringMap>

		<SelectCoresPopin
		v-if="selectCoresModal"
		:cores="cores"
		@closeModal="selectCoresModal = false"
		/>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import auth from "../../service/auth";
import CoringMap from "../../components/coring/Coring.Map.vue";
import MapFiltre from "../../components/map/Map.filtre.vue";
import CoringMapMenu from "../../components/coring/map/Coring.map.menu.vue";
import CoringMapFilter from "../../components/coring/map/Coring.map.filter.vue";
import html2canvas from "html2canvas";
import domToImage from "dom-to-image";
import SelectCoresPopin from "../../components/coring/map/SelectCores.popin.vue";

export default {
	name: "StructuralCoringCampaignMap",
	components: {
		CoringMap, MapFiltre, CoringMapMenu, CoringMapFilter, SelectCoresPopin
	},
	data(){
		return {
			isGuest: false,
			selectedLayers: [],
			pdfIsGenerating: false,
			selectCoresModal: false
		};
	},
	computed: {
		...mapGetters("coring", ["campaign", "cores"]),

		dashboardComponentName(){
			this.isGuest = auth.isLoggedGuest();
			return this.isGuest && !auth.isLogged()
				? "coring-campaign-dashboard-guest"
				: "coring-campaign-dashboard";
		}
	},
	methods: {
		...mapActions("coring", [
			"initStore", "purgeStore", "setLayersList"
		]),
		...mapMutations("coring", ["REMOVE_ALL_FILTER_BY_VALUES"]),
		async regeneratePdf(){
			//await this.captureAndDownload2();
			this.pdfIsGenerating = true;
			this.$api.coringCampaigns
			.getFromProvider(this.$route.params.id, true)
			.then(() => {
				this.getFiles(this.$route.query.path);
			});
		},
		async captureAndDownload(){
			const targetElement = document.getElementById("coringMap"); // Remplacez par la référence de l'élément que vous souhaitez capturer
			console.log(targetElement);
			const canvas = await html2canvas(targetElement,  {useCORS: true});

			// Convertir le canvas en base64
			const imageData = canvas.toDataURL("image/png");

			// Télécharger l'image localement
			this.downloadImage(imageData);
		},

		downloadImage(imageData){
			const link = document.createElement("a");
			link.href = imageData;
			link.download = "screenshot.png";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},


		async captureAndDownload2(){
			const targetElement = document.getElementById("coringMap");

			// Attendre que tous les éléments de la carte Mapbox soient chargés

			// Utiliser dom-to-image pour capturer l'image
			domToImage.toBlob(targetElement)
			.then((dataUrl) => {
				// Télécharger l'image localement
				this.downloadImage(dataUrl);
			})
			.catch((error) => {
				console.error("Erreur lors de la capture d'écran :", error);
			});
		}

	},
	async mounted(){
		this.isGuest = auth.isLoggedGuest();
		await this.initStore({id: this.$route.params.id, coringResult: true});
	},
	destroyed(){
		this.REMOVE_ALL_FILTER_BY_VALUES();
		this.purgeStore();
	}
};
</script>

<style lang="scss" scoped>
.menu {
	position: absolute !important;
	top: 10px !important;
	left: 16px !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: flex-start !important;
	justify-content: center !important;
	width: 400px !important;
	gap: 10px
}
</style>

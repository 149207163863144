<template>
	<section class="selectCorePopin">
		<v-card
		flat
		outlined
		class=""
		id="modale"
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>Selectionnez des carottes pour votre export PDF</h3>
			</v-card-title>

			<div>
				Liste des carottes :
			</div>

			<div class="tw-flex tw-flex-wrap tw-gap-[10px] tw-justify-center">
				<div
				v-for="core in cores"
				class="core-item"
				>
					<v-checkbox
					:value="core.id"
					v-model="selectedCores"
					hide-details
					>
						<template v-slot:label>
							<span :style="getHasAsbestos(core) ? 'color: red' : ''">
								{{ core.displayName ? core.displayNamePrefix.name + core.displayName :core.businessId }}
							</span>
						</template>
					</v-checkbox>
				</div>
			</div>
  
			<v-card-actions class="tw-flex tw-justify-around tw-w-full tw-mt-[10px]">
				<ButtonSlot @click="noButton">
					Fermer
				</ButtonSlot>

				<ButtonSlot @click="yesButton">
					Générer le PDF
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>
  
<script>
  
export default {
	name: "SelectCores",
	props: {
		cores: {
			type: Array,
			required: true
		}
	},
	data(){
	  return {
			selectedCores: []
	  };
	},
	methods: {
	  noButton(){
			this.$emit("closeModal");
	  },
	  yesButton(){
			const fileName = "coresExport";
			this.$api.cores.getCoresPdf(this.selectedCores).then(pdf => {
				this.downloadFile(pdf, fileName);
			}).catch(error => {
				console.log(error);
			});
	  },
	  downloadFile(blob, fileName){
			var newBlob = new Blob([blob], {type: "application/pdf"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = fileName;
			window.open(link, "_blank");
			this.setDownloadStatus({downloadState: "finished", element: fileName});
		},
		getHasAsbestos(core){
			let hasAsbestos = false;
			core.layersAndInterfaces.forEach(layerOrInterface => {
				if(layerOrInterface.type === "layer"){
					if(layerOrInterface.asbestosResult !== null){
						if(layerOrInterface.asbestosResult.containsAsbestos === true){
							hasAsbestos = true;
						}
					}
				}
			});

			return hasAsbestos;
		}
	},
	mounted(){
	}
};
</script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/_extends.scss";
  
  .selectCorePopin {
	@extend %popinView;
	.v-card {
	  width: 40%;
	  text-align: center;
	  padding: 20px 20px;
	  max-height: 90%;
	  overflow: auto;
	}
  
	&__card p {
	  text-align: left !important;
	  margin: 0 0 20px 0 !important;
	}
  }

  .core-item {
	flex: 1 1 calc(50% - 1rem);
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}
  </style>
  
